import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { BiLoader } from 'react-icons/bi'
import { toNormalCapitalization } from '../../Utilities/Utilities';
import { BsCheck } from 'react-icons/bs'
import { RiCloseFill } from 'react-icons/ri'
import { AiOutlineSearch } from 'react-icons/ai';

const STSProducts = (props) => {

    const [loading, setLoading] = useState() // Loading indicator

    // Inputs
    const productsRef = useRef({ value: '' }) // Used to paste the products and discounts into

    const [fullProductDetails, setFullProductDetails] = useState()

    // Unclear why we have these. TBC
    const [productError, setProductError] = useState(false) // Used to determine if there is an error with the products that have been entered
    const [productCheckError, setProductCheckError] = useState() // Likely to be a duplicate of the above productError - TBC

    // Used to hold product count and products remaining variables
    const [productCount, setProductCount] = useState(0); // Used to display the count of products entered into productsRef input
    const [productsRemaining, setProductsRemaining] = useState(0); // Used to display how many products remaining to be checked

    useEffect(() => {
        if (props.resetStatus) {
            setProductCheckError()
            setLoading(false)
            props.setProductCheck(true)
            props.setSupplierCheck(true)
            props.setDiscountCheck(true)
            setProductCount(0)
            setProductsRemaining(0)
            setFullProductDetails()

            productsRef.current.value = ''
        }
    }, [props.resetStatus])

    const handleProductFormChange = (e) => {
        const inputValue = e.target.value.toUpperCase();
        productsRef.current.value = inputValue;
    };

    // Used to determine if the discount amount is correct
    const isNumberInRange = (str) => {
        const convertedNumber = Number(str);
        return !isNaN(convertedNumber) && !Number.isNaN(parseFloat(str)) && convertedNumber >= 0 && convertedNumber <= 100000 && !str.includes('e');
    };

    const checkProductSupplierAndDiscount = (products) => {
        products && products.map((product) => {
            // Checks to make sure all products are active. Can probably be done later
            if (!product.exoActive == 'Y' || !product.pricebookActive == 'Y') {
                productCheckPassed = false
            }
        })
    }

    /**
     * Splits the content from the Products textarea and fetches the details for each product.
     */
    const getFullProductDetails = () => {
        setLoading(true)
        setProductError(false)
        setProductCheckError()
        props.setFullProductDetails([])

        props.setProductCheck(true)
        props.setSupplierCheck(true)
        props.setDiscountCheck(true)
        /* setDateCheck(false) */
        /* setReadyForContent(false) */

        let pCount = 0
        let pRemaining = 0

        setProductCount(0)
        setProductsRemaining(0)

        // Gets the products and discounts entered into the Form.Control
        const data = productsRef.current.value
            .trim()
            .split("\n")
            .filter((line) => line.trim() !== '')
            .map((line) => line.replace('$', ''));

        setFullProductDetails(null)

        // Splits the data up and adds them to the productInformationObjects. Also checks for duplicates
        const productInformationObjects = data.map((line) => {
            const [productCode, discount] = line.split(/[-\s\t]+/);
            pCount += 1
            pRemaining += 1
            setProductCount(pCount)
            setProductsRemaining(pRemaining)

            return { productCode: productCode, discount: discount };
        });

        const hasDuplicateProductCodes = (productInformationObjects) => {
            const productCodes = productInformationObjects.map((obj) => obj.productCode);
            const uniqueProductCodes = new Set(productCodes);

            return productCodes.length !== uniqueProductCodes.size
        };

        const validateDiscounts = (productInformationObjects) => {
            for (const product of productInformationObjects) {
                if (!isNumberInRange(product.discount)) {
                    return false;
                }
            }
            return true;
        };

        // Check that there are products
        if (pCount === 0) {
            setLoading(false)
            setProductError(true)
            props.setProductCheck(false)
            setProductCheckError("Please enter a product")
            return
        }

        // Check that there are no duplicates
        if (hasDuplicateProductCodes(productInformationObjects)) {
            setLoading(false)
            setProductError(true)
            props.setProductCheck(false)
            setProductCheckError("There are duplicate products")
            return
        }

        // Check that the discounts are numbers (between 0 and 100000)
        if (!validateDiscounts(productInformationObjects)) {
            props.setDiscountCheck(false);
            setProductError(true);
            setProductCheckError("Please enter valid discount amounts");
            return;
        }

        // Map through productInformationObjects to fetch full product details for each product
        const fetchPromises = productInformationObjects.map(async (product) => {
            const { productCode } = product;
            const { discount } = product;

            const url = `https://prod-04.australiaeast.logic.azure.com/workflows/87391eadffd64924bbbe572f5d7e6a47/triggers/manual/paths/invoke/ProductCode/${productCode}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=F-AK1lt-hPPBh46ePmfsHmOOKCy3fxtY7NYYa7_a1h4`;

            return fetch(url)
                .then((response) => {
                    pRemaining -= 1
                    setProductsRemaining(pRemaining)

                    if (response.status === 200) {
                        console.log("200:", response)
                        return response.json()
                    } else if (response.status === 204) {
                        props.setProductCheck(false)
                        console.log("204: ", response)
                        return null
                    }
                })
                .then((data) => {

                    const fullProductDetails = {
                        productCode: '',
                        secondaryCode: '',
                        discount: discount,
                        supplierName: '',
                        supplierId: '',
                        brandName: '',
                        brandId: '',
                        groupName: '',
                        groupId: '',
                        categoryName: '',
                        categoryId: '',
                        currentWebPrice: 0,
                        description: '',
                        exoActive: '',
                        pricebookActive: '',
                        subCategoryName: '',
                        subCategoryId: '',
                    }

                    if (data !== null) {
                        fullProductDetails.productCode = data.Table1[0].StockCode,
                            fullProductDetails.secondaryCode = data.Table1[0].SecondaryCode,
                            fullProductDetails.discount = discount,
                            fullProductDetails.supplierName = data.Table1[0].Supplier,
                            fullProductDetails.supplierId = data.Table1[0].SupplierID,
                            fullProductDetails.brandName = data.Table1[0].Brand,
                            fullProductDetails.brandId = data.Table1[0].BrandID,
                            fullProductDetails.groupName = data.Table1[0].Group,
                            fullProductDetails.groupId = data.Table1[0].GroupID,
                            fullProductDetails.categoryName = data.Table1[0].Category,
                            fullProductDetails.categoryId = data.Table1[0].CategoryID,
                            fullProductDetails.currentWebPrice = data.Table1[0].CurrentWebPrice,
                            fullProductDetails.description = data.Table1[0].Description,
                            fullProductDetails.exoActive = data.Table1[0].EXOActive,
                            fullProductDetails.pricebookActive = data.Table1[0].PBActive,
                            fullProductDetails.subCategoryName = data.Table1[0].SubCategory,
                            fullProductDetails.subCategoryId = data.Table1[0].SubCategoryID;
                    } else {
                        fullProductDetails.productCode = productCode
                        fullProductDetails.secondaryCode = ''
                        fullProductDetails.discount = discount
                        fullProductDetails.supplierName = ''
                        fullProductDetails.supplierId = ''
                        fullProductDetails.brandName = ''
                        fullProductDetails.brandId = ''
                        fullProductDetails.groupName = ''
                        fullProductDetails.groupId = ''
                        fullProductDetails.categoryName = ''
                        fullProductDetails.categoryId = ''
                        fullProductDetails.currentWebPrice = 0
                        fullProductDetails.description = ''
                        fullProductDetails.exoActive = ''
                        fullProductDetails.pricebookActive = ''
                        fullProductDetails.subCategoryName = ''
                        fullProductDetails.subCategoryId = ''
                    }

                    // Find index of this product in productInformationObjects and replace it with fullProductDetails
                    const index = productInformationObjects.findIndex((p) => p.productCode === productCode)
                    productInformationObjects[index] = fullProductDetails

                    // Return the updated productInformationObject
                    return fullProductDetails;
                })
                .catch((error) => {
                    console.log("Error: ", error)
                }
                );
        });

        // Wait for all fetch requests to finish before updating state
        Promise.all(fetchPromises).then((fullProductDetailsArray) => {
            fullProductDetailsArray.sort((a, b) => a.productCode.localeCompare(b.productCode));

            setFullProductDetails(fullProductDetailsArray);
            checkProductSupplierAndDiscount(fullProductDetailsArray)
            props.setFullProductDetails(fullProductDetailsArray)
            generateLineItems(fullProductDetailsArray)
            generateScheduleDetails(fullProductDetailsArray)
        }).then(() => {
            setLoading(false)
            props.setReadyForContent(true)
        }).catch((error) => {
            props.setFullProductDetails()
            setFullProductDetails()
            setLoading(false)
            setProductError(true)
            console.log("We should show a red line around the products box: ", error.message)
        })
    };

    const generateScheduleDetails = (products) => {

        const productArray = products

        let firstPass = true
        let supplierMatch = true
        let groupMatch = true
        let categoryMatch = true

        let tempSupplier = ''
        let tempGroup = ''
        let tempCategory = ''

        let returnTitle = ''
        let returnDescription = ''
        let returnProducts = ''

        /**
         * If there are products in the product array then we should generate a Product based STS result
         * 
         * This iterates through each product, and determines if the supplier, group, or categories match
         * 
         */
        products && products.forEach((product) => {
            if (firstPass) {
                tempSupplier = product.supplierName
                tempGroup = product.groupName
                tempCategory = product.categoryName

                firstPass = false
                supplierMatch = true
            } else {
                if (tempSupplier !== product.supplierName) {
                    supplierMatch = false
                }

                if (tempGroup !== product.groupName) {
                    groupMatch = false
                }

                if (tempCategory !== product.categoryName) {
                    categoryMatch = false
                }
            }

            //const productLine = `${product.productCode}${product.secondaryCode ? ` - ${product.secondaryCode}` : ''} - $${product.discount}`;
            let productLine = product.productCode
            console.log("Product Line: ", productLine)

            product.discount && (productLine = productLine + ` - $${product.discount}`)
            console.log("Product Line: ", productLine)

            product.secondaryCode && (productLine = productLine + ' - ' + product.secondaryCode)
            console.log("Product Line: ", productLine)

            product.description && (productLine = productLine + ' - ' + product.description)
            console.log("Product Line: ", productLine)

            returnProducts += productLine + "\n";
        })

        returnTitle = tempSupplier + " STS on "
        returnDescription = tempSupplier + " is offering STS on:" + "\n\n"

        if (productArray.length === 1) {
            returnTitle = returnTitle + productArray[0].productCode
        } else if (categoryMatch) {
            returnTitle = returnTitle + "Selected " + toNormalCapitalization(tempCategory)
        } else if (groupMatch) {
            returnTitle = returnTitle + "Selected " + toNormalCapitalization(tempGroup)
        } else {
            returnTitle = returnTitle + "Selected Products"
        }

        props.setScheduleDetails({
            ...props.scheduleDetails,
            Name: returnTitle,
            InitialDescription: `${returnDescription}${returnProducts}`
        })
    }

    /* Generates the LineItems and stores them in lineItems variable */
    const generateLineItems = (products) => {
        let lineItemsArray = []

        products && products.map((product) => {
            const lineItem = {
                productCode: product.productCode,
                discount: product.discount,
                SupplierID: product.supplierId,
                BrandID: product.brandId,
                CAT1: product.categoryId,
                CAT2: product.subCategoryId,
                RebateValue: product.discount,
                RebatePercent: 0,
            }
            lineItemsArray.push(lineItem)
        })

        props.setLineItems(lineItemsArray)

    }

    return (
        <Row>
            <Col md={{ span: 12 }} lg={{ span: 3, offset: 0 }} className="overflow-auto">
            <div className='p-3 mb-5 bg-white shadow'>
                    <div className='mb-2 text-small px-2 accent fw-bold'>Enter Products and Discounts</div>
                    <Form.Control as="textarea" rows={6}
                        id="productsTextarea"
                        ref={productsRef}
                        className={`borderless no-resize ${!productError ? 'borderless-input' : 'error-border'}`}
                        placeholder={"MW513 - 25.25\nWH8060p3 12.55\nDW60UNT4B2 $25.55"}
                        aria-label="Product list"
                        aria-describedby="Product list"
                        onChange={handleProductFormChange}
                        style={{ fontSize: '0.75em', borderRadius: "0px" }}
                    />

                    <Row className='flex-column'>
                        <Col className=''>
                            <div className='text-danger fs-small py-2'>
                                {productCheckError && `${productCheckError}`}
                            </div>
                        </Col>
                        <Col>
                            <Button
                                className='w-100 mt-3 bw-primary-outline'
                                onClick={getFullProductDetails}>
                                {loading ? <BiLoader className='spin' /> : <AiOutlineSearch/>}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>

            <Col md={{ span: 12 }} lg={{ span: 9, offset: 0 }} className="overflow-auto pb-3">
                {loading && !fullProductDetails &&
                    <Row className='p-5'>
                        <Col className='text-center'>
                            <div className='text-dark'>{loading && <BiLoader className='spin' size="24" />}</div>
                        </Col>
                    </Row>
                }

                {fullProductDetails &&
                    <div className='px-0 pt-2 mb-3 bg-white shadow'>
                        {/* <div className='mb-2 text-small px-2 accent fw-bold'>Product Information</div> */}
                        <Table variant=''>
                            <thead>
                                <tr className='text-small'>
                                    <th className='text-center'>Active</th>
                                    <th className='text-left'>Product (Secondary)</th>
                                    <th>Discount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fullProductDetails && fullProductDetails.map((p, i) => {
                                    return (
                                        <tr key={i} className='fs-small'>
                                            <td className='text-center'>
                                                {p.exoActive === 'Y' && p.pricebookActive === 'Y' ?
                                                    <BsCheck className="accent" size="20" /> : <RiCloseFill className="text-danger" size="20" />
                                                }
                                            </td>
                                            <td className='text-left'>
                                                <Stack>
                                                    <div className=''>{p.productCode.toUpperCase()} {p.secondaryCode && `(${p.secondaryCode})`}</div>
                                                </Stack>
                                            </td>
                                            <td className=''>${p.discount}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                }

            </Col>
        </Row>
    )
}

export default STSProducts