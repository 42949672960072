import React, { useEffect, useRef, useState } from "react";

// Date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import nz from "date-fns/locale/en-NZ";
import { Col, Form, Row } from "react-bootstrap";
registerLocale("en-NZ", nz);

const STSContent = (props) => {
  const [supplierRefType, setSupplierRefType] = useState("Not Required"); // Variable for storing the Supplier Ref Type (Not required, bucket, etc)
  const supplierRef = useRef({ value: "" }); // Input box for the supplier reference field

  // Used as storage for the date pickers
  const [startDate, setStartDate] = useState(new Date()); // Used to store the start date
  const [endDate, setEndDate] = useState(new Date()); // Used to store the end date
  const [claimDate, setClaimDate] = useState(new Date()); // Used to store the claim date

  const [dateCheck, setDateCheck] = useState(false); // Used to determine if the dates fit the criteria
  const [countOfClaimDates, setCountOfClaimDates] = useState([]);

  const titleRef = useRef({ value: "" }); // STS Title input reference
  const descriptionRef = useRef({ value: "" }); // STS Description input reference

  useEffect(() => {
    if (props.resetStatus) {
      setStartDate(new Date());
      setEndDate(new Date());
      setClaimDate(new Date());
      setDateCheck(false);

      setCountOfClaimDates([]);
      setSupplierRefType("Not Required");

      descriptionRef.current.value = "";
      titleRef.current.value = "";
    }

    getCountOfClaimDates();
  }, [props.resetStatus]);

  useEffect(() => {}, [props.scheduleDetails]);

  const updateSTSDates = (newStartDate, newEndDate, newClaimDate) => {
    // Console for checking if dates are coming in as expected.
    console.log(newStartDate, newEndDate, newClaimDate)

    // Extract the date portion from the datetime objects to compare if dates are in correct order.
    const start = new Date(newStartDate).toISOString().split("T")[0];
    const end = new Date(newEndDate).toISOString().split("T")[0];
    const claim = new Date(newClaimDate).toISOString().split("T")[0];

    // The start date must be less than the end date, and the end date must be less than the claim date and end date
    if (start <= end && end < claim) {
      setDateCheck(true);
    } else {
      setDateCheck(false);
      return;
    }

    // Get the string formatted values for the description field
    const startDateString = format(newStartDate, "EEEE do MMMM yyyy");
    const endDateString = format(newEndDate, "EEEE do MMMM yyyy");
    const claimDateString = format(newClaimDate, "EEEE do MMMM yyyy");

    const dateRangeLayout = `\nThis offer is valid from ${startDateString} to ${endDateString}`;
    const claimDateLayout = `\n\nComplete and submit with supporting evidence by 10pm ${claimDateString}`;

    const title = getSTSDescription().title;

    props.setScheduleDetails({
      ...props.scheduleDetails,
      Name: title,
      Description:
        props.scheduleDetails.InitialDescription +
        dateRangeLayout +
        claimDateLayout,
      PromoStart: newStartDate,
      PromoEnd: newEndDate,
      ClaimDate: newClaimDate,
    });

    props.setReadyForMembers(true);
  };

  const getCountOfClaimDates = () => {
    const url =
      "https://prod-29.australiaeast.logic.azure.com/workflows/9dad52fc558d40929f0c1c1fe5e595a6/triggers/manual/paths/invoke/api/?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=X0O4Vs6q6PrN2O1NKKBdOWs3eZq9g3sJkJH5KbukS1k";

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCountOfClaimDates(data.Table1);
      })
      .catch((error) => console.log(error));
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;

    props.setScheduleDetails({
      ...props.scheduleDetails,
      Name: newTitle,
    });
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    props.setScheduleDetails({
      ...props.scheduleDetails,
      Description: newDescription,
    });
  };

  const updateSupplierReference = (e) => {
    let ref = e.target.value;

    if (e.target.value === "Custom") {
      ref = supplierRef.current?.value;
    }

    props.setScheduleDetails({
      ...props.scheduleDetails,
      supplierReference: ref,
    });
  };

  const updateCustomReference = () => {
    const supRef = supplierRef.current?.value;

    props.setScheduleDetails({
      ...props.scheduleDetails,
      supplierReference: supRef,
    });
  };

  const getSTSDescription = () => {
    return {
      title: props.scheduleDetails.Name,
      description: props.scheduleDetails.InitialDescription,
    };
  };

  const getFridayCloseDate = (tempDate) => {
    const date = new Date(tempDate)

    const daysToAdd = [12, 11, 10, 9, 8, 7, 13];
    const dayIndex = date.getDay();

    const newDate = new Date(addDaysToDate(date, daysToAdd[dayIndex]));

    return newDate;
  };

  const addDaysToDate = (inputDate, numberOfDays) => {
    // I hate this so much... but, due to confusion around how the times are being handled (e.g. some in UTC, some in GMT, etc) the 'get next fridays date' 
    // function returns the wrong day (offset by -1) if created in the AM. So, instead, I'l always setting the time to 2pm so that even if it takes off 12 hours or 11 hours, it will always
    // return the right day. I know... sorry if you have to fix this.
    const convertedDate = inputDate.setHours(14,0,0,0)

    const date = new Date(convertedDate);
    date.setDate(date.getDate() + numberOfDays);

    const returnVal = date.toISOString().split("T")[0]; // Format: 'YYYY-MM-DD'

    return returnVal
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <div className="mb-2 text-small accent fw-bold">
            Supplier Reference
          </div>
          <Form className="d-flex text-small align-items-center">
            {["Not Required", "Bucket", "Retrospective", "Custom"].map(
              (type, index) => {
                return (
                  <Form.Check
                    key={index}
                    className="mt-1"
                    inline
                    type="radio"
                    name="radio-group"
                    value={type}
                    id={`radio-${index}`}
                    label={`${type}`}
                    checked={supplierRefType === type}
                    onChange={(e) => {
                      updateSupplierReference(e);
                      setSupplierRefType(type);
                    }}
                  />
                );
              }
            )}

            <Form.Control
              id="supplierReference"
              ref={supplierRef}
              className="borderless-input my-3 text-small"
              placeholder="Custom Supplier Reference"
              style={{ maxWidth: "280px" }}
              aria-label="Supplier Reference"
              aria-describedby="Supplier Reference"
              onChange={updateCustomReference}
              disabled={supplierRefType !== "Custom"}
            />
          </Form>
        </Col>

        <Col className="col-12">
          <Row>
            <Col className="d-flex text-small">
              <div className="me-3">
                <p className="text-small fw-bold">Start Date</p>

                <DatePicker
                  locale="en-NZ"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    updateSTSDates(date, endDate, claimDate);
                  }}
                />
              </div>
              <div className="me-3">
                <p className="text-small fw-bold">End Date</p>
                <DatePicker
                  locale="en-NZ"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setClaimDate(getFridayCloseDate(date));
                    updateSTSDates(startDate, date, getFridayCloseDate(date));
                  }}
                />
              </div>

              <div className="me-3">
                <p className="text-small fw-bold">Claim Date</p>
                <DatePicker
                  locale="en-NZ"
                  dateFormat="dd/MM/yyyy"
                  selected={claimDate}
                  /*dayClassName={(date) => {
                    const claim = countOfClaimDates.find(
                      (c) => new Date(c.ClaimDate).getTime() === date.getTime()
                    );
                    return claim && claim.Closing >= 6 ? "red" : undefined;
                  }}*/
                  onChange={(date) => {
                    setClaimDate(date);
                    updateSTSDates(startDate, endDate, date);
                  }}
                />
              </div>
            </Col>
          </Row>

          {dateCheck && (
            <Row className="mt-3">
              <Col className="col-12 text-small">
                <p className="fw-bold">Title</p>
                <Form.Control
                  id="stsTitle"
                  ref={titleRef}
                  className="borderless-input text-small"
                  aria-label="Sell-Through Title"
                  aria-describedby="Sell-Through Title"
                  defaultValue={
                    props.scheduleDetails && props.scheduleDetails.Name
                  }
                  onChange={handleTitleChange}
                />

                <p className="text-small fw-bold pt-3">Description</p>
                <Form.Control
                  as="textarea"
                  rows={10}
                  id="descriptionTextArea"
                  ref={descriptionRef}
                  className="borderless-input text-small"
                  aria-label="STS Description"
                  aria-describedby="STS Description"
                  value={
                    props.scheduleDetails && props.scheduleDetails.Description
                  }
                  onChange={handleDescriptionChange}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default STSContent;
