import React, { createContext, useState } from 'react';

// create a context object
export const UserContext = createContext(null);

// create a provider component that will wrap the top-level component in your application
export function UserProvider({ children }) {
  const [hasAccess, setHasAccess] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  const login = (code) => {
    if(code === process.env.REACT_APP_ACCESS_CODE) {
      setHasAccess(true)
      setHasAdminAccess(false)
    } else if (code === process.env.REACT_APP_ADMIN_ACCESS_CODE) {
      setHasAccess(false)
      setHasAdminAccess(true)
    } else {
      setHasAccess(false)
      setHasAdminAccess(false)
    }
  }

  return (
    <UserContext.Provider value={{ hasAccess, setHasAccess, hasAdminAccess, setHasAdminAccess, login }}>
      {children}
    </UserContext.Provider>
  );
}
