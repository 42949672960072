import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import STSProducts from "./STS/STSProducts"; // Products section
import STSCategory from "./STS/STSCategory"; // Category section
import STSContent from "./STS/STSContent"; // Content section
import STSMembers from "./STS/STSMembers"; // Members section
import ResultModal from "./ResultModal";
import { BsCheck } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";

const SellThroughSupport = () => {
  const [showOption, setShowOption] = useState("createSTS");

  // Used to reset all the content on each tab
  const [resetStatus, setResetStatus] = useState(false);

  // Used to enable / disable tabs
  const [readyForContent, setReadyForContent] = useState(false);
  const [readyForMembers, setReadyForMembers] = useState(false);
  const [stage, setStage] = useState(0); // 0 = products/categories 1 = Layout 2 = Members

  const [productBasedSTS, setProductBasedSTS] = useState(true);

  const [resultObject, setResultObject] = useState({
    title: "placeholder",
    id: 0,
  });

  const [activeTab, setActiveTab] = useState("products");

  const [scheduleDetails, setScheduleDetails] = useState({
    Name: "",
    InitialDescription: "",
    Description: "",
    PromoStart: "",
    PromoEnd: "",
    ClaimDate: "",
    Notification1: new Date(),
    Status: 1,
    CreatedBy: "AutoBot",
    supplierReference: "Not Required",
    sent_to_store: "N",
  });

  const [lineItems, setLineItems] = useState({});

  const [fullProductDetails, setFullProductDetails] = useState([]);

  const [modalShowResult, setModalShowResult] = useState(false);

  // Used to determine if there is an issue with products, suppliers, or discounts
  const [productCheck, setProductCheck] = useState(true);
  const [supplierCheck, setSupplierCheck] = useState(true);
  const [discountCheck, setDiscountCheck] = useState(true);

  useEffect(() => {
    document.title = "ACL Tools";
  }, [scheduleDetails, scheduleDetails.Name]);

  useEffect(() => {}, [readyForContent, readyForMembers]);

  const resetApp = () => {
    setReadyForContent(false);
    setReadyForMembers(false);
    setStage(0);
    setFullProductDetails([]);

    setLineItems({});
    setScheduleDetails({
      Name: "",
      InitialDescription: "",
      Description: "",
      PromoStart: "",
      PromoEnd: "",
      ClaimDate: "",
      Notification1: new Date(),
      Status: 1,
      CreatedBy: "AutoBot",
      supplierReference: "Not Required",
      sent_to_store: "N",
    });

    setResultObject({
      title: "Success",
      id: 0,
    });

    setResetStatus(false);
    handleTabChange("products");
  };

  /**
   * Saves the STS to the database
   *
   * Payload is an array of objects { [{ scheduleDetails }, { lineItems }, { recipients: selectedMembers }] }
   *
   */
  const saveSTS = (recipients) => {
    saveNewSTS([
      { scheduleDetails: scheduleDetails },
      { lineItems: lineItems },
      { recipients: recipients },
    ]);
  };

  async function saveNewSTS(payload) {
    console.log("Payload: ", payload);

    const scheduleDetailsURL =
      "https://prod-20.australiaeast.logic.azure.com/workflows/620514d995914c8198bd9337b7d1234b/triggers/manual/paths/invoke/api/CreateSTS?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=63e8wIiExJ3qikESLj9zn0768LxIhtgjW0qbT26W5HA";

    try {
      const scheduleDetailsRequest = await fetch(scheduleDetailsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await scheduleDetailsRequest.json();

      setResultObject({
        title: "Success",
        id: data.Table1[0].ID,
      });

      setModalShowResult(true);
      setResetStatus(true);
    } catch (error) {
      console.log("Error: ", error.message);
      setResultObject({
        title: "Error",
        id: 0,
      });

      setModalShowResult(true);
    }
  }

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const changeStage = (direction) => {
    let tempStage = stage;

    if (direction === "next") {
      tempStage += 1;
      setStage(tempStage);
    } else {
      resetApp();
    }
  };

  return (
    <div className="d-flex mt-5">
      
      {/** Aside */}
      <div className="aside bg-dark text-light w-25">
        <div className="text-small mb-2 fw-bold">Rebate Type</div>
        <ButtonGroup className="pb-3">
          <Form.Check
            type="switch"
            id="rebate-type"
            className="custom-checkbox"
            checked={productBasedSTS}
            onChange={() => {
              resetApp();
              setProductBasedSTS(!productBasedSTS);
            }}
          />
          <Form.Label className="text-small ms-3">
            {productBasedSTS ? "Product" : "Category"}
          </Form.Label>
        </ButtonGroup>

        {/** Displays the product check information */}
        {productBasedSTS && fullProductDetails.length > 0 && (
          <div>
            <div className="text-small mb-2 fw-bold">Product Check</div>
            <div className="mb-2 text-small">
              Products: {fullProductDetails.length}{" "}
            </div>
            <div className="mb-2">
              {productCheck ? (
                <BsCheck className="text-light" size="20" />
              ) : (
                <RiCloseFill className="text-danger" size="20" />
              )}
              <span className="ms-3 text-small">Products</span>
            </div>
            <div className="mb-2">
              {supplierCheck ? (
                <BsCheck className="text-light" size="20" />
              ) : (
                <RiCloseFill className="text-danger" size="20" />
              )}
              <span className="ms-3 text-small">Suppliers</span>
            </div>
            <div className="mb-2">
              {discountCheck ? (
                <BsCheck className="text-light" size="20" />
              ) : (
                <RiCloseFill className="text-danger" size="20" />
              )}
              <span className="ms-3 text-small">Discounts</span>
            </div>
          </div>
        )}

        {/** Displays the Supplier, Brand, and Discount fields for category based STS */}
      </div>

      <div className="main p-3">
        {/* {showOption === 'createSTS' && */}
        {stage === 0 && productBasedSTS && (
          <>
            <Button
              className="previous-button shadow hover btn mb-1"
              variant="outline-light"
              onClick={() => changeStage("reset")}
            >
              Restart
            </Button>

            <STSProducts
              scheduleDetails={scheduleDetails}
              setScheduleDetails={setScheduleDetails}
              setFullProductDetails={setFullProductDetails}
              lineItems={lineItems}
              setLineItems={setLineItems}
              resetStatus={resetStatus}
              setReadyForContent={setReadyForContent}
              changeStage={changeStage}
              productCheck={productCheck}
              supplierCheck={supplierCheck}
              discountCheck={discountCheck}
              setProductCheck={setProductCheck}
              setSupplierCheck={setSupplierCheck}
              setDiscountCheck={setDiscountCheck}
            />

            {readyForContent && (
              <Button
                className="next-button shadow bw-primary"
                onClick={() => changeStage("next")}
              >
                Next
              </Button>
            )}
          </>
        )}
        {stage === 0 && !productBasedSTS && (
          <>
            <STSCategory
              scheduleDetails={scheduleDetails}
              setScheduleDetails={setScheduleDetails}
              lineItems={lineItems}
              setLineItems={setLineItems}
              setReadyForContent={setReadyForContent}
              resetStatus={resetStatus}
            />

            <Button
              className="previous-button shadow hover btn mb-1"
              variant="outline-light"
              onClick={() => changeStage("reset")}
            >
              Restart
            </Button>

            {readyForContent && (
              <Button
                className="next-button shadow bw-primary"
                onClick={() => changeStage("next")}
              >
                Next
              </Button>
            )}
          </>
        )}

        {/* {readyForContent && 1 == 2 && */}
        {stage === 1 && readyForContent && (
          <>
            <STSContent
              scheduleDetails={scheduleDetails}
              setScheduleDetails={setScheduleDetails}
              lineItems={lineItems}
              resetStatus={resetStatus}
              setReadyForMembers={setReadyForMembers}
              fullProductDetails={fullProductDetails}
            />
            <Button
              className="previous-button shadow hover btn mb-1"
              variant="outline-light"
              onClick={() => changeStage("reset")}
            >
              Restart
            </Button>

            {readyForMembers && (
              <Button
                className="next-button shadow bw-primary"
                onClick={() => changeStage("next")}
              >
                Next
              </Button>
            )}
          </>
        )}

        {/* {readyForMembers && 1 == 2 && */}
        {stage === 2 && readyForMembers && (
          <>
            <Button
              className="previous-button shadow hover btn mb-1"
              variant="outline-light"
              onClick={() => changeStage("reset")}
            >
              Restart
            </Button>

            <STSMembers
              scheduleDetails={scheduleDetails}
              setScheduleDetails={setScheduleDetails}
              saveSTS={saveSTS}
              resetStatus={resetStatus}
            />
          </>
        )}
      </div>

      <ResultModal
        resultObject={resultObject}
        show={modalShowResult}
        onHide={() => {
          setModalShowResult(false);
          resetApp();
        }}
      />
    </div>
  );
};

export default SellThroughSupport;
