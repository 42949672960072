import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ResultModal = (props) => {
    return (
        <Modal 
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={props.resultObject.title == 'Success' ? 'bg-success text-light' : 'bg-danger text-light'}>
                <Modal.Title id="contained-modal-title-vcenter">
                   {props.resultObject.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This has been created under { props.resultObject.id }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResultModal