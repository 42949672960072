import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { GiPodiumWinner } from "react-icons/gi";
import { RiRefreshLine } from "react-icons/ri";
import ModalReviewSTS from "./ModalReviewSTS";
import { formatDate } from "../Utilities/Utilities.js";

const ReviewSTS = () => {
  const [loading, setLoading] = useState();
  const [rebatesForReview, setRebatesForReview] = useState();
  /* const [membersForReview, setMembersForReview] = useState() */
  const [showSTSForReview, setShowSTSForReview] = useState(false);
  const [selectedSTS, setSelectedSTS] = useState({});
  const [showRefreshTooltip, setShowRefreshTooltip] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRebatesForReview();
  }, []);

  useEffect(() => {}, []);

  const getRebatesForReview = async () => {
    const url =
      "https://prod-25.australiaeast.logic.azure.com/workflows/ace7d00da97243a1b8b117b6a6d2b91e/triggers/manual/paths/invoke/api/GetRebatesForReview?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=sTJ50EO8Nixt6T8h8CtzRIhoWqTd6zr9j71oQvoSJVA";

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.Table1) {
          setRebatesForReview(data.Table1);
          setLoading(false);
        } else {
          console.log("No data received");
          setLoading(false);
          setRebatesForReview();
        }
      })
      .catch((error) => {
        console.log(error);
        setRebatesForReview();
      });
  };

  const initiateReview = (e) => {
    rebatesForReview &&
      rebatesForReview.map((rebate) => {
        if (e.target.outerText == rebate.ID) {
          setSelectedSTS(rebate);
        }
      });

    setShowSTSForReview(true);
  };

  return (
    <div className="mt-5 mx-5 p-3">
      {!loading && rebatesForReview && (
        <Row className="pt-3 pb-1">
          <Col>
            <div className="bg-white">
              <Table variant="" className="text-small">
                <thead>
                  <tr>
                    <th className="text-center">Rebate ID</th>
                    <th className="text-left">Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Claim Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {rebatesForReview &&
                    rebatesForReview.map((rebate, i) => {
                      return (
                        <tr key={`review-${i}`}>
                          <td className="text-center">
                            <Button
                              className="text-button"
                              onClick={(e) => initiateReview(e)}
                            >
                              <u>{rebate["ID"]}</u>
                            </Button>
                          </td>
                          <td className="text-left">{rebate["Name"]}</td>
                          <td>{formatDate(rebate["PromoStart"])}</td>
                          <td>{formatDate(rebate["PromoEnd"])}</td>
                          <td>{formatDate(rebate["ClaimDate"])}</td>
                          <td>{rebate["Status"] == 1 && "Saved"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}

      <Row className="">
        <Col className="col-12">
          {loading && (
            <div className="bw-subtle text-center">
                <VscLoading className="spin" />
            </div>
          )}

          {!loading && !rebatesForReview && (
            <div className="bw-subtle text-center" style={{opacity:"0.35"}} >
                <GiPodiumWinner size={48} className=""/><br />
                Nothing to review
            </div>
          )}
        </Col>
      </Row>

      {selectedSTS && (
        <ModalReviewSTS
          show={showSTSForReview}
          onHide={() => setShowSTSForReview(false)}
          selectedSTS={selectedSTS}
          refresh={getRebatesForReview}
        />
      )}
    </div>
  );
};

export default ReviewSTS;
