import React, { useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { formatDate } from "../Utilities/Utilities.js";
import { VscTrash } from "react-icons/vsc";

const ModalReviewSTS = (props) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    {
      props.selectedSTS && props.selectedSTS.ID && getMembersForRebateID();
    }

    console.log("Props: ", props);
  }, [props.selectedSTS]);

  useEffect(() => {}, [members]);

  const getMembersForRebateID = () => {
    const url = `https://prod-04.australiaeast.logic.azure.com/workflows/093c53958e1f4924ae231317195fac57/triggers/manual/paths/invoke/api/GetMembersForRebateID/${props.selectedSTS.ID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ayjJTygacBbA26DAkpKWLjSbLXwSItKEPVfl9cYhQOA`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setMembers(data.Table1);
        console.log("Data: ", data.Table1);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteSTS = () => {
    console.log("Delete STS with id ", props.selectedSTS.ID);

    const url = `https://prod-02.australiaeast.logic.azure.com/workflows/7b433d5eb29b405da4491d9cebe9bc59/triggers/manual/paths/invoke/api/DeleteRebateWithID/${props.selectedSTS.ID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OrlGU_mQ7ldAmJsfdAXuogbb4oRCVW4elibxuU-7JJM`;

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        props.onHide();
        props.refresh();
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  };

  const publishSTS = () => {
    const url = `https://prod-29.australiaeast.logic.azure.com/workflows/c7832b6cfd1d47aa8b1f7e0acacd25c8/triggers/manual/paths/invoke/api/PublishRebateWithID?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oFiVN5S7vphgZvEZZyxnyJ4geFbNpNZL8OGY5GFX2XM`;

    const id = { json: props.selectedSTS.ID };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(id),
    };

    fetch(url, requestOptions)
      .then((response) => response.status)
      .then((data) => {
        console.log("Completed - should be published: ", data);
        props.onHide();
        props.refresh();
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {props.selectedSTS && (
        <Modal
          show={props.show}
          fullscreen={true}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bw-primary text-light">
            <Modal.Title id="contained-modal-title-vcenter d-flex">
              <div>
                {props.selectedSTS.ID} - {props.selectedSTS.Name}
              </div>
            </Modal.Title>
            <div data-bs-theme="light" className="bg-transparent px-3">
              <CloseButton variant="white" onClick={props.onHide} />
            </div>
          </Modal.Header>

          <Modal.Body className="d-flex flex-column">
            <Row>
              <Col>
            <div className="py-1 d-flex justify-content-between">
                <div>
                  <b>Supplier Reference:</b> {props.selectedSTS.SupplierReference}
                </div>
                <div className="float-end">
                <Button
                  className="float-end ms-3"
                  onClick={publishSTS}
                  variant="success"
                >
                  Publish
                </Button>{" "}
                <Button
                  className="float-end"
                  onClick={deleteSTS}
                  variant="danger"
                >
                  <VscTrash size={20} />
                </Button>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center border-bottom py-3 ">
              <div>
                <b>Starting:</b> {formatDate(props.selectedSTS.PromoStart)}
              </div>
              <div>
                <b>Ending:</b> {formatDate(props.selectedSTS.PromoEnd)}
              </div>
              <div>
                <b>Claim by:</b> {formatDate(props.selectedSTS.ClaimDate)}
              </div>
            </div>
            </Col>
            </Row>
            
            <Row className="flex-grow-1 overflow-hidden">
              <Col className="col-12 col-lg-8">
                <h5 className="py-3">Description</h5>
                <Form.Control
                  as="textarea"
                  rows={15}
                  defaultValue={props.selectedSTS.Description}
                  readOnly
                />
              </Col>

              <Col className="col-12 col-lg-4 h-100">
                <h5 className="py-3">Members</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "auto",
                    backgroundColor:"#F5F5F5",
                  }}
                >
                  <table className="table">
                    <tbody>
                      {members &&
                        members.map((member, i) => {
                          return (
                            <tr key={i}>
                              <td className="fs-small">{member.Name}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ModalReviewSTS;
