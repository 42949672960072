import './App.css';
import { useContext, useEffect } from 'react';
import { BiLogIn } from 'react-icons/bi'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom'

import Calculator from './Components/Calculator';

import { UserContext } from './Hooks/userContext';
import Targets from './Components/Targets';
import SellThroughSupport from './Components/SellThroughSupport';
import AllSTS from './Components/AllSTS';
import ReviewSTS from './Components/ReviewSTS';

function App() {

  const { hasAccess, hasAdminAccess, login } = useContext(UserContext)

  useEffect(() => {
    document.title = "ACL Tools"
  }, [hasAccess])

  return (
    <>
      {!hasAccess && !hasAdminAccess &&
        <Row className="d-flex justify-content-center p-0 m-0 h-100">
          <Col className="col-12 col-lg-3 p-3">
            <InputGroup className="pt-5">
              <Form.Control
                className='text-center'
                id="login"
                type='password'
                placeholder="Access code"
                aria-label="Access code"
                aria-describedby="Access code"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    login(document.getElementById("login").value)
                  }
                }}
              />

              <Button
                className='btn bw-accent borderless pb-2'
                onClick={() => login(document.getElementById("login").value)}>{<BiLogIn />}</Button>

            </InputGroup>
          </Col>
        </Row>
      }


      {hasAccess &&
        <Routes>
          { /*
          <Route path="/" element={<Calculator />} />
          <Route path="/calculator" element={<Calculator />} />
          */ }
        </Routes>
      }

      {hasAdminAccess &&
        <Routes>
          <Route path="/" element={<SellThroughSupport />} />
          { /* <Route path="/calculator" element={<Calculator />} /> */ }
          <Route path="/sts/all" element={<AllSTS />} />
          <Route path="/sts/create" element={<SellThroughSupport />} />
          <Route path="/sts/review" element={<ReviewSTS />} />
        </Routes>
      }
    </>
  );
}

export default App;
