/**
 * To be completed, bugs, etc
 *  
 * 
 * Font sizes within the Margin + GP boxes wrap if they are too large. Handle this.
 * 
 */

import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { VscClose } from 'react-icons/vsc'
import { BiLoader } from 'react-icons/bi'
import { AiFillInfoCircle, AiOutlineArrowRight, AiOutlineSearch } from 'react-icons/ai'
import { MdOutlineClose } from 'react-icons/md'
import { Accordion, AccordionButton, Button, Card, Col, Form, InputGroup, Overlay, Row, Table, Tooltip } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

const Calculator = () => {

  const [results, setResults] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const [currentProduct, setCurrentProduct] = useState()

  const salePriceRef = useRef({ value: '' });
  const storeCostRef = useRef({ value: '' });
  const productRef = useRef({ value: '' });

  const target = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  /***
   * All calculations should be done from the pricing information object
   * 
   * Including GST:
   * Cost
   * 
   * Excluding GST:
   * Price, sellThroughDollar, sellThroughPercent, sellThroughSPIV
   * 
   */
  const [pricingInformation, setPricingInformation] = useState({
    storeCost: 0.00,
    salePrice: 0.00,
    offInvoiceDiscount: 0,
    sellThroughDollar: 0.00,
    sellThroughPercent: 0,
    sellThroughSPIV: 0.00,
  })

  const [comparisonSwitch, setComparisonSwitch] = useState(false)

  const isSmallScreen = useMediaQuery({ maxWidth: 992 }); // 576 is the sm breakpoint, 992 is large

  //Used for custom purchase cost, or custom sale price
  const [customStoreCost, setCustomStoreCost] = useState(false)
  const [customSalePrice, setCustomSalePrice] = useState(false)

  useEffect(() => {
    document.title = "Calculator";
  }, [results])

  const resetForm = () => {
    setResults(null)
    setError('')

    setComparisonSwitch(false)

    document.getElementById('productCode').value = ''
    if(salePriceRef.current) {
      salePriceRef.current.value = ''
    }
    if(storeCostRef.current) {
      storeCostRef.current.value = ''
    }
  }

  const getProductData = () => {
    setLoading(true)
    setResults(null)
    setComparisonSwitch(false)

    const productCode = productRef.current.value || ''
    setCurrentProduct(productCode)

    if(salePriceRef.current) {
      salePriceRef.current.value = ''
    }
    if(storeCostRef.current) {
      storeCostRef.current.value = ''
    }
     

    setError('')



    const fetchURL = 'https://prod-23.australiasoutheast.logic.azure.com/workflows/d7ad565b2bee427eb0f16e38aff57dd3/triggers/manual/paths/invoke/ProductCode/' + productCode + '?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wHRr523eV3WOFc9HPC4nM4IwVYEVrOmPBS0nms2mjws'
    /*const fetchURL = 'https://prod-19.australiaeast.logic.azure.com/workflows/d5ce9ba10f174933830ba4848228aba0/triggers/manual/paths/invoke/api/GetProductCode/' + productCode + '?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TCXNR5MTvzF2Dzw5E3M-DsapcvtXEyvdnNKaFv5UZEQ'*/

    const historicPricingURL = 'https://prod-05.australiasoutheast.logic.azure.com/workflows/663d9b4250964e54825ba3dfd62a1579/triggers/manual/paths/invoke/ProductCode/' + productCode + '?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=boO8Hr7tqBcuOELYyRiuSmJgwwszduorVcULJ31yaMs'

    /* Get Product information */
    fetch(fetchURL)
      .then(response => response.json())
      .then(data => {
        setResults(data.Table1[0])
        setPricingInformation({
          storeCost: data.Table1[0].TRADECOST,
          salePrice: data.Table1[0].CURRENTWEBCOST,
          offInvoiceDiscount: data.Table1[0].OFFINVOICE / 100, // This is received as a whole percent e.g. 10 for 10%
          sellThroughDollar: data.Table1[0].MODELSPECIFIC,
          sellThroughPercent: data.Table1[0].SELLTHROUGHSPECIFIC,
          sellThroughSPIV: data.Table1[0].SPIV
        })
        if (data.Table1[0].TRADECOST == null) {
          setError("Product not found")
          setResults()
        }

        setLoading(false)
      }
      )
      .catch(error => {
        setLoading(false)
        setError("Product not found")
      })
  }

  const formatDollarValue = (val) => {
    let numericVal = Number(val);
    if (isNaN(numericVal)) {
      numericVal = 0.00
    }

    return numericVal.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  const formatPercentValue = (val) => {
    return val.toLocaleString('en-NZ', { style: 'percent', minimumFractionDigits: 2 })
  }

  const toNormalCapitalization = (str) => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => {
      return a.toUpperCase();
    });
  };

  const getNettCost = () => {
    let nettCost = pricingInformation.storeCost * (1 - pricingInformation.offInvoiceDiscount) - getSTSDiscountAmount()

    return nettCost
  }

  const getMargin = () => {
    let margin = pricingInformation.salePrice - (getNettCost() * 1.15)

    return margin

  }

  const getGP = () => {
    let grossProfit = pricingInformation.salePrice - (getNettCost() * 1.15);
    let grossProfitPercentage = (grossProfit / pricingInformation.salePrice) * 100;

    return (grossProfitPercentage / 100)
  }

  const getSTSDiscountAmount = () => {
    let discountAmount = (pricingInformation.storeCost * pricingInformation.sellThroughPercent) + pricingInformation.sellThroughSPIV + pricingInformation.sellThroughDollar

    return discountAmount
  }

  const handleCustomSalePrice = () => {
    if (salePriceRef.current.value.length == 0) { return }

    const salePriceValue = salePriceRef.current.value /*|| pricingInformation.salePrice*/
    setCustomSalePrice(true)

    if (!comparisonSwitch) {
      // If Sale Price is chosen
      setPricingInformation({
        ...pricingInformation,
        salePrice: salePriceValue,
      })
    } else {
      // If margin is selected
      const minimumSalePrice = calculateMinimumSalePrice(getNettCost(), salePriceValue)

      setPricingInformation({
        ...pricingInformation,
        salePrice: minimumSalePrice,
      })
    }
  }

  const handleClearCustomSalePrice = () => {
    setCustomSalePrice(false)

    setPricingInformation({
      ...pricingInformation,
      salePrice: results.CURRENTWEBCOST
    })

    salePriceRef.current.value = ''
  }

  const handleCustomStoreCost = () => {
    if (storeCostRef.current.value.length == 0) { return }

    const storeCostValue = storeCostRef.current.value || pricingInformation.storeCost
    setCustomStoreCost(true)

    setPricingInformation({
      ...pricingInformation,
      storeCost: storeCostValue,
    })
  }

  const handleClearCustomStoreCost = () => {
    setCustomStoreCost(false)

    setPricingInformation({
      ...pricingInformation,
      storeCost: results.TRADECOST
    })

    storeCostRef.current.value = ''
  }

  const calculateMinimumSalePrice = () => {
    const salePriceValue = salePriceRef.current.value

    const minimumMargin = 1 + (salePriceValue / 100);
    const minimumSalePrice = (pricingInformation.storeCost * minimumMargin)

    return minimumSalePrice
  }

  return (
    <Row className='p-1 pt-2 m-0 px-lg-5 pt-lg-5 pb-lg-1 m-lg-0 mt-lg-5'>

      {/* Product search panel */}
      <Col className={`col-12 col-lg-3 ${!isSmallScreen && 'position-fixed'} rounded`}>
        <Card className='mb-3 p-0'>
          <Card.Header className='bw-primary text-light' >
            <Card.Title>Product Search</Card.Title>
          </Card.Header>
          <Card.Body>

            <Row>
              <Col><h5>Search</h5></Col>
              <Col className='text-right'>
                {error && <span className='loading-text text-danger'>{ error }</span>}
              </Col>
            </Row>

            <InputGroup className="mb-3">
              <Form.Control
                id="productCode"
                ref={productRef}
                className=''
                placeholder="Product Code"
                aria-label="Product Code"
                aria-describedby="Product Code"
                onChange={(event) => {
                  event.target.value = event.target.value.toUpperCase();
                }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    getProductData()
                  }
                }}
              />

              <Button
                className='btn bw-accent borderless-input pb-2'
                onClick={() => getProductData()}>{!loading ? <AiOutlineSearch /> : <BiLoader className='spin' />}</Button>

              <Button
                className='btn bw-danger borderless-input pb-2'
                onClick={() => resetForm()}>{<VscClose />}</Button>

            </InputGroup>

            {currentProduct && results &&
              <div className="d-none d-lg-block">
                <h5 className='text-dark'>Product Info</h5>
                <div className='product-description rounded'>
                  <div className='display-linebreak fs-small'>{results && toNormalCapitalization(results.DESCRIPTION)}</div>
                </div>
              </div>
            }
          </Card.Body>
        </Card>
      </Col>

      {/* Pricing content panel*/}
      <Col md={{ span: 12 }} lg={{ span: 8, offset: 4 }} className="overflow-auto">

        {results && <>
          <Accordion defaultActiveKey="0" className='mb-3'>
            <Accordion.Item eventKey="0">
              <AccordionButton className='bw-primary text-light'>Price Calculator {productRef && ' - ' + productRef.current.value}</AccordionButton>
              <Accordion.Body className=''>

                <Row className=''>
                  <Col className='col-12 col-lg-5'>

                    <div className='d-inline-block'>
                      <span className='pe-3'>{!comparisonSwitch ? "Sale Price" : "Minimum Margin"}</span>
                      <Form.Check
                        className='pe-3 d-inline-block'
                        checked={comparisonSwitch}
                        disabled={!results}
                        type="switch"
                        id="comparisonSwitch"
                        onChange={() => {
                          setComparisonSwitch(!comparisonSwitch)
                          salePriceRef.current.value = ''
                          setCustomSalePrice()
                          setPricingInformation({
                            ...pricingInformation,
                            salePrice: results.CURRENTWEBCOST
                          })
                        }
                        }
                      />
                      <div className='text-muted fs-small pb-3'>Including GST</div>
                    </div>

                    {/** Sale price input group */}
                    <InputGroup className="mb-3">
                      {!comparisonSwitch ?
                        <InputGroup.Text id="">$</InputGroup.Text>
                        :
                        <InputGroup.Text id="">%</InputGroup.Text>
                      }
                      <Form.Control
                        id="salePrice"
                        ref={salePriceRef}
                        className=''
                        placeholder="0.00"
                        aria-label="Sell price"
                        aria-describedby="Sell price"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            handleClearCustomSalePrice()
                          }
                        }}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            handleCustomSalePrice()
                          }
                        }}
                      />

                      <Button
                        className='btn bw-accent pb-2 borderless-input'
                        onClick={handleCustomSalePrice}
                      >
                        <AiOutlineArrowRight />
                      </Button>

                      {salePriceRef.current && salePriceRef.current.value.length > 0 &&
                        <Button
                          className='btn bw-danger pb-2 close-button'
                          onClick={handleClearCustomSalePrice}>
                          <MdOutlineClose />
                        </Button>
                      }

                    </InputGroup>
                  </Col>

                  <Col md={{ span: 12 }} lg={{ span: 5, offset: 2 }} >
                    <div className=''>Store Cost
                      <sup>
                        <button className='tooltip-button accent'
                          ref={target}
                          onMouseOver={() => setShowTooltip(true)}
                          onMouseOut={() => setShowTooltip(false)}
                        >
                          <AiFillInfoCircle />
                        </button>
                      </sup>
                      <Overlay target={target.current} show={showTooltip} placement="right">
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            Enter your own cost price, excluding GST, if you purchased this product at a different price to the ACL Invoice Price.
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>
                    <div className='text-muted fs-small pb-3'>Excluding GST</div>

                    <InputGroup className="mb-3">
                      <InputGroup.Text id="">$</InputGroup.Text>
                      <Form.Control
                        id="storeCost"
                        ref={storeCostRef}
                        className=''
                        placeholder="0.00"
                        aria-label="Product Cost"
                        aria-describedby="Product Cost"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            handleClearCustomStoreCost()
                          }
                        }}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            handleCustomStoreCost()
                          }
                        }}
                      />

                      <Button
                        className='btn bw-accent pb-2 borderless'
                        onClick={handleCustomStoreCost}
                      >
                        <AiOutlineArrowRight />
                      </Button>

                      {customStoreCost &&
                        <Button
                          className='btn bw-danger pb-2 close-button'
                          onClick={handleClearCustomStoreCost}>
                          {<MdOutlineClose />}
                        </Button>
                      }
                    </InputGroup>
                  </Col>
                </Row>
                <Row className='py-4'>
                <Col className='d-flex align-items-center col-12 col-lg-3 mb-3'>
                    <Card className='p-0 text-center h-100 w-100'>
                      <Card.Header className='bw-primary text-light fw-bold'>
                        { customSalePrice ? "Custom Price" : "Web Price" }
                      </Card.Header>
                      <Card.Body className='pb-3 d-flex flex-column justify-content-center'>
                          <div className={getMargin() >= 0 ? 'px-3 fw-bold dynamic-font-size' : 'text-danger px-3 fw-bold dynamic-font-size'}>
                            { customSalePrice && results && formatDollarValue(pricingInformation.salePrice)}
                            { !customSalePrice && results && formatDollarValue(results.CURRENTWEBCOST)}
                          </div>
                        <div className='fs-small'> incl. GST </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col className='d-flex align-items-center col-12 col-lg-3 mb-3'>
                    <Card className='p-0 text-center h-100 w-100'>
                      <Card.Header className='bw-primary text-light fw-bold'>
                        {!comparisonSwitch ? "Margin $" : "Sell for $"}
                      </Card.Header>
                      <Card.Body className='pb-3 d-flex flex-column justify-content-center'>
                        {!comparisonSwitch ?
                          <div className={getMargin() >= 0 ? 'px-3 fw-bold dynamic-font-size' : 'text-danger px-3 fw-bold dynamic-font-size'}>{results && formatDollarValue(getMargin())}</div>
                          :
                          <div className={pricingInformation.salePrice >= 0 ? 'px-3 fw-bold dynamic-font-size' : 'text-danger px-3 fw-bold dynamic-font-size'}>
                            {results && customSalePrice ? formatDollarValue(calculateMinimumSalePrice()) : ' - '}
                          </div>
                        }
                        <div className='fs-small'> incl. GST </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col className='d-flex align-items-center col-12 col-lg-3 mb-3'>
                    <Card className='p-0 text-center w-100 h-100'>
                      <Card.Header className={'bw-primary text-light fw-bold'}>
                        GP %
                      </Card.Header>
                      <Card.Body className='d-flex flex-column justify-content-center'>
                        {/**
                         * BUG: The text-danger logic doesn't work if the result is 0. It formats it as 0 instead of 0.00. 
                         * The colors don't change properly around the 0% margin and the formatPercentValue doesn't seem to work if the result is 0
                         * 
                         * You can replicate by putting in the Actual Cost as the Sale Price
                         */}
                        {!comparisonSwitch ?
                          <>
                            <div className={getGP() >= 0 ? 'px-3 dynamic-font-size fw-bold' : 'text-danger px-3 dynamic-font-size fw-bold'}>{results && formatPercentValue(getGP())}</div>
                            <div className='fs-small'> incl. GST </div>
                          </>
                          :
                          <>
                            <div className={getGP() >= 0 ? 'px-3 fw-bold dynamic-font-size' : 'text-danger px-3 fw-bold dynamic-font-size'}>
                              {results && customSalePrice ? formatPercentValue(getGP()) : ' - '}
                            </div>
                            <div className='fs-small'> incl. GST </div>
                          </>
                        }
                      </Card.Body>
                    </Card>

                  </Col>

                </Row>
                <Row>
                  <Col>
                    <Table className='table p-0 m-0'>
                      <thead className='bw-primary text-light'>
                        <tr>
                          <th>Pricing</th>
                          <th>Incl. GST</th>
                          <th>Excl. GST</th>
                        </tr>
                      </thead>
                      <tbody className='fs-small bg-light'>
                        <tr>
                          <td className='fw-bold'>{customStoreCost ? "Custom store cost" : "ACL Invoice Price"}</td>
                          <td>{pricingInformation && formatDollarValue(pricingInformation.storeCost * 1.15)}</td>
                          <td>{pricingInformation && formatDollarValue(pricingInformation.storeCost)}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>Rebate</td> { /* Updated from off invoice discount */ }
                          <td>{pricingInformation && formatPercentValue(pricingInformation.offInvoiceDiscount * 1.15)}</td>
                          <td>{pricingInformation && formatPercentValue(pricingInformation.offInvoiceDiscount)}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>Sell Through</td>
                          <td>{formatDollarValue(getSTSDiscountAmount() * 1.15)}</td>
                          <td>{formatDollarValue(getSTSDiscountAmount())}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>Actual Cost</td>
                          <td>{results && formatDollarValue(getNettCost() * 1.15)}</td>
                          <td>{results && formatDollarValue(getNettCost())}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>{customSalePrice ? "Custom Sale Price" : "Current Web Price"}</td>
                          <td>{results && formatDollarValue(pricingInformation.salePrice)}</td>
                          <td>{results && formatDollarValue(pricingInformation.salePrice / 1.15)} </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>}
      </Col>
    </Row>
  )
}

export default Calculator