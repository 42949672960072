import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const Targets = () => {

useEffect(() => {
    document.title = "Targets";
}, [])

    return (
        <Row className='m-0'>
            <Col>
                <span className='text-light'>Some new targets.</span>
            </Col>
        </Row>
    )
}

export default Targets