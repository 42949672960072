import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { HiOutlineSearch } from 'react-icons/hi'
import { VscLoading } from 'react-icons/vsc';


const AllSTS = () => {
    const [rebateRegister, setRebateRegister] = useState()
    const [filteredRegister, setFilteredRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const searchRef = useRef()

    useEffect(() => {
        GetRebateRegister()
        setLoading(true)
        console.log("Getting rebate register.")
    }, [])

    const GetRebateRegister = async () => {
        const url = "https://prod-07.australiaeast.logic.azure.com/workflows/c4fd58e52c154ab2b65607d8e9076045/triggers/manual/paths/invoke/api?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bN2dfF-8H7kr0Q2EkG4xOx0bSJR58uNoO5AruxQF_0E"

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setRebateRegister(data.Table1)
                setLoading(false)
            })
            .catch((error) => console.log(error));
    }

    const filterRebates = () => {
        const ref = searchRef.current.value

        /* setFilteredRegister(rebateRegister.filter(obj => obj["RebateID"] == ref)) */
        /* setFilteredRegister(rebateRegister.filter(obj => obj["RebateID"] == ref || obj["Rebate Title"])); */
        setFilteredRegister(rebateRegister.filter(obj => obj["RebateID"].toString().toLowerCase().includes(ref.toLowerCase()) || obj["Rebate Title"].toLowerCase().includes(ref.toLowerCase())))

    }

    const clearFilteredRebates = () => {
        searchRef.current.value = ''
        setFilteredRegister(null)
    }

    return (
        <>
            <Row className='px-5 pt-3 pb-3'>
                <h2 className='text-light pb-3'>All Sell-Through Support</h2>
                <Col className='col-12 col-lg-4'>
                    <InputGroup>
                        <InputGroup.Text>Filter: </InputGroup.Text>
                        <Form.Control
                            ref={searchRef}
                            type="text"
                            placeholder=""
                            onChange={() => filterRebates()}
                        />
                    <Button onClick={ () => filterRebates() } variant='success'><HiOutlineSearch /></Button>
                    <Button onClick={ () => clearFilteredRebates() } variant='danger'>X</Button>
                </InputGroup>
            </Col>
        </Row >
            <Row className='px-5'>
                <Col>
                    {!loading &&
                        <Table variant='light'>
                            <thead>
                                <tr>
                                    <th>Rebate ID</th>
                                    <th className='text-left'>Title</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Claim Date</th>
                                    <th>Total Claims</th>
                                    <th>Member Paid</th>
                                    <th>Rebate Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!filteredRegister && rebateRegister && rebateRegister.map((rebate, i) => {
                                    return (
                                        <tr key={`register-${i}`}>
                                            {/* Using this method as there are spaces in the key names */}
                                            <td>{rebate["RebateID"]}</td>
                                            <td className='text-left'>{rebate["Rebate Title"]}</td>
                                            <td>{rebate["Start Date"]}</td>
                                            <td>{rebate["End Date"]}</td>
                                            <td>{rebate["Claim Date"]}</td>
                                            <td>{rebate["Total Claims"]}</td>
                                            <td>{rebate["Member Paid"]}</td>
                                            <td>{rebate["Rebate Status"]}</td>
                                        </tr>
                                    )
                                })
                                }

                                {filteredRegister && filteredRegister.map((rebate, i) => {
                                    return (
                                        <tr key={`filtered-${i}`}>
                                            {/* Using this method as there are spaces in the key names */}
                                            <td>{rebate["RebateID"]}</td>
                                            <td className='text-left'>{rebate["Rebate Title"]}</td>
                                            <td>{rebate["Start Date"]}</td>
                                            <td>{rebate["End Date"]}</td>
                                            <td>{rebate["Claim Date"]}</td>
                                            <td>{rebate["Total Claims"]}</td>
                                            <td>{rebate["Member Paid"]}</td>
                                            <td>{rebate["Rebate Status"]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }

                    { loading && 
                        <div className='text-light'><VscLoading className='spin'/></div>
                    }
                       
                    
                </Col>
            </Row>
        </>
    )
}

export default AllSTS