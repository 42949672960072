import { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { UserContext } from "../Hooks/userContext";
import { Button, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

const Menu = () => {
  const { hasAccess, hasAdminAccess, setHasAccess, setHasAdminAccess } =
    useContext(UserContext);


  return (
    <Navbar className="bw-primary" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand>
          <Nav.Link as={ NavLink } to="/" className="text-light" style={{width:"180px"}}>
            ACL Group
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {hasAccess && (
            <>
              <div className="d-flex">
                <Button
                  variant=""
                  className="me-5"
                  onClick={() => {
                    setHasAccess(false);
                  }}
                >
                  Logout
                </Button>
              </div>
            </>
          )}

          {hasAdminAccess && (
            <>
              <Nav className="me-auto">
                <Nav.Link
                  as={NavLink}
                  to="/STS/create"
                  className="menuLink text-light"
                >
                  Create
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to={"/STS/all"}
                  className="menuLink text-light"
                >
                  Rebate List
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/STS/review"
                  className="menuLink text-light"
                >
                  Review
                </Nav.Link>
              </Nav>

              <div className="d-flex">
                <Button
                  variant="link"
                  className="me-2 text-light"
                  onClick={() => {
                    setHasAdminAccess(false);
                  }}
                >
                  Logout
                </Button>
              </div>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
