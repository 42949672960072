import React, { useEffect, useState } from 'react';
import { Table, Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { BiLoader } from 'react-icons/bi';

const STSMembers = (props) => {
    const [rebateMembers, setRebateMembers] = useState([])
    const [radioValue, setRadioValue] = useState(false);
    const [saving, setSaving] = useState(false)

    // Used when saving to send either an empty object (all members) or a list of contact IDs (selected members)
    const [selectedMembers, setSelectedMembers] = useState([])

    useEffect(() => {
        if(props.resetStatus) {
            setRadioValue(false)
            setSelectedMembers([])
            setRebateMembers([])
            setSaving(false)
        }

        getRebateMembers()
    }, [props.resetStatus])

    const getRebateMembers = () => {
        const url = "https://prod-14.australiaeast.logic.azure.com/workflows/71c6c457aef749fea57427290cb28929/triggers/manual/paths/invoke/GetRebateMembers?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5ia7lfo4HqxT_m9IYCi_BofI0fq2YXl2LF73zatJvlQ"

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setRebateMembers(data.value.sort((a, b) => a.NAME.localeCompare(b.NAME)))
                console.log(data.value)
            })
            .catch((error) => console.log(error));
    }

    const handleCheckboxChange = (event, memberId, alphaCode) => {
        const isChecked = event.target.checked;

        setSelectedMembers(prevState => {
            if (isChecked) {
                // Add the memberId and alphaCode to the selectedMembers array
                const updatedMembers = [...prevState, { memberId, alphacode: alphaCode }];
                return updatedMembers;
            } else {
                // Remove the object with the matching memberId from selectedMembers
                const updatedMembers = prevState.filter(member => member.memberId !== memberId);
                return updatedMembers;
            }
        });

        
    };

    return (
        <>
            <Row className='mb-3'>
                <Col>
                    <h5 className=''>Recipients</h5>
                    <ButtonGroup>
                        <Form.Check
                            type="switch"
                            className='me-2'
                            id="custom-switch"
                            checked={radioValue}
                            onChange={() => {
                                setRadioValue(!radioValue)
                                if (radioValue) {
                                    setSelectedMembers([])
                                }

                            }}
                        />
                        <Form.Label>{!radioValue ? "All Members" : "Selected Members"}</Form.Label>
                    </ButtonGroup>
                </Col>
                <Col>
                    <Button
                        className='save-button shadow hover bw-primary'
                        onClick={() => {
                            setSaving(true)
                            props.saveSTS(selectedMembers)
                        }}
                    >{ !saving ? 'Save' : <BiLoader className='spin' /> }</Button>
                </Col>
            </Row>

            {radioValue &&
                <Row className='mb-5'>
                    <Col>
                        <Table variant="light">
                            <thead>
                                <tr>
                                    <th className='text-center'>Include</th>
                                    <th className='text-left'>Member</th>
                                    <th className='text-left'>Name</th>
                                    <th className='text-center'>Contact ID</th>
                                    <th>AlphaCode</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rebateMembers && rebateMembers.map((member, i) => {
                                    const memberId = member.MEMBER_ID;
                                    const isChecked = selectedMembers.some(member => member.memberId === memberId);

                                    return (
                                        <tr key={i}>
                                            <td className='text-center'>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`selectedMember-${i}`}
                                                    checked={isChecked}
                                                    onChange={(event) => handleCheckboxChange(event, memberId, member.ALPHACODE)}
                                                />

                                            </td>
                                            <td className='text-left'>{member.NAME}</td>
                                            <td className='text-left'>{member.FIRST_NAME} {member.LAST_NAME}</td>
                                            <td>{member.ALPHACODE}</td>
                                            <td>{member.MEMBER_ID}</td>
                                        </tr>
                                    );
                                })}


                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
        </>
    )
}

export default STSMembers