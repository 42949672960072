import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Form, FormGroup, InputGroup, Row, Table } from 'react-bootstrap';
import { FaMinus, FaCheck } from 'react-icons/fa'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { toNormalCapitalization } from '../../Utilities/Utilities';


const STSCategory = (props) => {

    const [rebateCategories, setRebateCategories] = useState([])
    const [selectedGroup, setSelectedGroup] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedSupplier, setSelectedSupplier] = useState({})
    const [selectedBrand, setSelectedBrand] = useState({})

    const [selectedLineItems, setSelectedLineItems] = useState([])

    const [suppliers, setSuppliers] = useState([])
    const [brands, setBrands] = useState([])

    const discountRef = useRef()
    const supplierRef = useRef()
    const brandRef = useRef()

    useEffect(() => {
        getRebateCategories()
        getSupplierList()
        getBrandList()
    }, [])

    useEffect(() => {
        generateLineItems()
        generateScheduleDetails()
    }, [selectedLineItems])

    const getSupplierList = () => {
        const url = 'https://prod-23.australiaeast.logic.azure.com/workflows/5d322050ab9f44bd8e3ecea53705777b/triggers/manual/paths/invoke/api/GetSupplierList?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=O6BlTfpvdDy3OXDb8-GJuA86guic2fRH2NL7yHHCIxk'

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setSuppliers(data.Table1)
                console.log("Suppliers: data.table1: ", data.Table1)
            }).catch((error) => {
                console.log("Error: ", error.message)
            })
    }

    const getBrandList = () => {
        const url = 'https://prod-28.australiaeast.logic.azure.com/workflows/cfd6e1f93e3548c4ba6b3deb7cd14338/triggers/manual/paths/invoke/api/GetBrandList?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Y2SBjcmotOGmp1RqDN3SI7jyOlbqgQ8TCskCAO2gPio'

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setBrands(data.Table1)
                console.log("Brands: data.table1: ", data.Table1)
            }).catch((error) => {
                console.loog("Error: ", error.message)
            })
    }

    const getRebateCategories = () => {
        const url = "https://prod-03.australiaeast.logic.azure.com/workflows/fcc09fd9a712495494da67eb07566534/triggers/manual/paths/invoke/api/GetRebateCategories?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=fS5EiIIwkWONm5iKd82seUEpfq79YKDslBTcNX6rHKA"

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setRebateCategories(data.Table1)
                console.log("Categories: ", data.Table1)
            })
            .catch((error) => console.log(error));
    }

    const addFullCategory = (category) => {
        let tempArr = [...selectedLineItems]

        rebateCategories && rebateCategories.map((record) => {
            if (record.Group === selectedGroup && record.Category === category) {
                tempArr.push(record)
            }
        })

        setSelectedLineItems(tempArr)
    }

    const removeFullCategory = (category) => {
        let tempArr = [...selectedLineItems];

        tempArr = tempArr.filter(
            (record) => !(record.Group === selectedGroup && record.Category === category)
        );

        setSelectedLineItems(tempArr);
    };

    const addSubCategory = (subCategory) => {
        let tempArr = [...selectedLineItems]

        rebateCategories && rebateCategories.map((record) => {
            if (record.Group === selectedGroup && record.Category === selectedCategory && record.SubCategory === subCategory) {
                tempArr.push(record)
            }
        })

        setSelectedLineItems(tempArr)
    }

    const removeSubCategory = (subCategory) => {
        let tempArr = [...selectedLineItems];

        tempArr = tempArr.filter(
            (record) => !(record.Group === selectedGroup && record.Category === selectedCategory && record.SubCategory === subCategory)
        );

        setSelectedLineItems(tempArr);
    };

    /**
     * Need to generate the scheduleDetails:
     * 
     * Name, Description, PromoStart, PromoEnd, ClaimDate, Status, CreatedBy, SentToStore, SupplierReference
     */
    const generateScheduleDetails = () => {
        // Generate the title

        const uniqueCategories = [...new Set(selectedLineItems.map((lineItem) => `${lineItem.Category}`))];
        let categoryTitleModifier = 'Selected Categories';

        // If there's only one category, change the title to include that category
        if(uniqueCategories.length === 1) {
            categoryTitleModifier = uniqueCategories[0]
        }
        
        const name = `${selectedSupplier.name} - ${discountRef.current.value}% STS on ${categoryTitleModifier}`

        let categoryDescription = ''

        uniqueCategories && uniqueCategories.map((cat) => {
            categoryDescription += `${cat} \n`
        })

        const description = `${selectedSupplier.name} are offering ${discountRef.current.value}% STS on:\n\n${categoryDescription}`

        props.setScheduleDetails({
            ...props.scheduleDetails,
            InitialDescription: description,
            Name: name,
        })
    }

    const generateLineItems = () => {
        let lineItemsArray = []

        const disc = discountRef.current ? discountRef.current.value : 0;

        selectedLineItems && selectedLineItems.map((lineItem) => {
            const li = {
                productCode: null,
                RebatePercent: disc,
                GroupName: selectedGroup,
                SupplierID: selectedSupplier.id,
                SupplierName: selectedSupplier.name,
                BrandID: selectedBrand.id || null,
                BrandName: selectedBrand.name || null,
                CAT1: lineItem.CAT1,
                CategoryName: lineItem.CategoryName,
                CAT2: lineItem.CAT2 || null,
                SubCategoryName: lineItem.SubCategoryName,
                BuyPrice: 0.00,
                GoPrice: 0.00,
                RebateValue: 0,
                RebatePercent: disc,
                BonusItem: null
            }

            lineItemsArray.push(li)
        })

        props.setLineItems(lineItemsArray)

        if(lineItemsArray.length) {
            props.setReadyForContent(true)
        }
        
    }

    return (
        <>
            { /* Supplier, brand, and discount fields */}
            <Row className='mb-3'>
                <Col className='col-2'>
                    <div className='mb-2 text-small pe-2 accent fw-bold'>Supplier <span className='text-danger'>*</span></div>
                    {/* <div className='text-small text-muted mb-2'>Select the supplier</div> */}
                    {suppliers &&
                        <Typeahead
                            id='supplierDropdown'
                            ref={supplierRef}
                            placeholder='Supplier'
                            onChange={(selected) => {
                                if (selected[0]) {
                                    const selectedSupplier = suppliers.find(
                                        (supplier) => supplier.Name === selected[0]
                                    );
                                    if (selectedSupplier) {
                                        const selectedSupplierID = selectedSupplier.SUP_ID;
                                        setSelectedSupplier({ name: selectedSupplier.Name, id: selectedSupplierID })
                                    }
                                }
                            }}
                            options={suppliers.map((supplier) => supplier.Name)}
                        />
                    }
                </Col>

                <Col className='col-2'>
                    <div className='mb-2 text-small pe-2 accent fw-bold'>Brand</div>
                    {/* <div className='text-small text-muted mb-2'>Only select Brand if the STS is brand specific</div> */}
                    {brands &&
                        <Typeahead
                            id='brandDropdown'
                            ref={brandRef}
                            placeholder='Brand'
                            onChange={(selected) => {
                                if (selected[0]) {
                                    const selectedBrand = brands.find(
                                        (brand) => brand.BrandCode === selected[0]
                                    );
                                    if (selectedBrand) {
                                        const selectedBrandID = selectedBrand.BrandID;
                                        setSelectedBrand({ name: selectedBrand.BrandCode, id: selectedBrandID });
                                    }
                                }
                            }}

                            options={brands.map((brand) => brand.BrandCode)}
                        />
                    }
                </Col>

                <Col className='col-2'>
                    <div className='mb-2 text-small pe-2 accent fw-bold'>Discount <span className='text-danger'>*</span></div>
                    {/* <div className='text-small text-muted mb-2'>Enter discount amount</div> */}
                    <InputGroup className='w-50'>
                        <Form.Control
                            ref={discountRef}
                            id='discountAmount'
                            placeholder='0'
                            className='text-right'
                            type='text'
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                </Col>

            </Row>

            <hr className='text-dark' />

            { /* Group, Category, Sub-category */}
                <div className='p-3 mb-3 bg-white shadow text-small'>
                    <div className='text-small fw-bold'>Select Categories</div>
                    <Row style={{ maxHeight: "100%", overflow: "hidden" }}>

                        { /* Group */}
                        <Col className='col-4'>

                            <Table variant=''>
                                <thead>
                                    <tr>
                                        <th>Group</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rebateCategories && [...new Set(rebateCategories.map(record => record.Group))]
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((group, index) => {

                                            const highlightRow = selectedLineItems.some(record => record.Group === group);

                                            return (
                                                <tr key={index}>
                                                    <td className={selectedGroup === group ? 'highlightRow pointer d-flex' : 'pointer d-flex'} onClick={() => setSelectedGroup(group)}>
                                                        {highlightRow ? <FaCheck className='me-3 text-success' /> : <FaMinus className='me-3 text-warning' />}
                                                        { toNormalCapitalization(group) }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>
                        </Col>

                        { /* Category */}
                        <Col className='col-4'>
                            <Table variant=''>
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rebateCategories && [...new Set(rebateCategories.filter(record => record.Group === selectedGroup).map(record => record.Category))]
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((category, index) => {
                                            const recordExists = selectedLineItems.some(record => record.Group === selectedGroup && record.Category === category);
                                            const isChecked = recordExists ? true : false;

                                            return (

                                                <tr className='' key={index}>
                                                    <td className='pointer' onClick={() => setSelectedCategory(category)}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`selectedCategory-${index}`}
                                                            inline
                                                            checked={isChecked}
                                                            onChange={(e) => e.target.checked ? addFullCategory(category) : removeFullCategory(category)}
                                                        />{ toNormalCapitalization(category) }</td>
                                                </tr>
                                            )
                                        })}

                                </tbody>
                            </Table>
                        </Col>

                        { /* Sub Category */}
                        <Col className='col-4'>
                            <Table variant=''>
                                <thead>
                                    <tr>
                                        <th>Sub Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rebateCategories && [...new Set(rebateCategories.filter(record => record.Group === selectedGroup && record.Category === selectedCategory).map(record => record.SubCategory))]
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((subcategory, index) => {
                                            const recordExists = selectedLineItems.some(record => record.Group === selectedGroup && record.Category === selectedCategory && record.SubCategory === subcategory);
                                            const isChecked = recordExists ? true : false;

                                            return (
                                                <tr className='' key={index}>
                                                    <td className='pointer'>
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`selectedSubCategory-${index}`}
                                                            inline
                                                            checked={isChecked}
                                                            onChange={(e) => e.target.checked ? addSubCategory(subcategory) : removeSubCategory(subcategory)}
                                                        />
                                                        { toNormalCapitalization(subcategory) }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
        </>
    )
}

export default STSCategory