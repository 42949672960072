import React from 'react';

export const toNormalCapitalization = (str) => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => {
      return a.toUpperCase();
    });
  };

  /*
  export const formatDate = (d) => {
    const options = { weekday: 'short', day: '2-digit', month: '2-digit', year: '2-digit' };
    const formattedDate = new Date(d).toLocaleDateString('en-NZ', options);
  
    return formattedDate;
  }
  */

  export const formatDate = (d) => {
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const date = new Date(d);
    const day = date.getDate();
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
  
    const formattedDate = `${weekday}, ${day}${ordinalSuffix(day)} ${month}, ${year}`;
  
    return formattedDate;
  };
  
  // Helper function to add ordinal suffix (e.g., 1st, 2nd, 3rd)
  function ordinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  